// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    min-height: 100vh;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    background: white;
}

.react-error-overlay {
    display: none !important;
}
#webpack-dev-server-client-overlay-div {
    display: none !important;
}

#webpack-dev-server-client-overlay {
    display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".App {\n    min-height: 100vh;\n    /*display: flex;*/\n    justify-content: center;\n    align-items: center;\n    background: white;\n}\n\n.react-error-overlay {\n    display: none !important;\n}\n#webpack-dev-server-client-overlay-div {\n    display: none !important;\n}\n\n#webpack-dev-server-client-overlay {\n    display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
