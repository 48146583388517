import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo } from 'react';
import MuultiNavBar from "./components/MuultiNavBar";
import { Router, Routes, Route, BrowserRouter } from "react-router-dom";
import MuultiHomePage from "./pages/MuultiHomePage";
import MuultiPlaylistDetailPage from "./pages/MuultiPlaylistDetailPage";
import VideoDetailPage from "./pages/VideoDetailPage";
import LoginPage from "./pages/LoginPage";
import {AuthenticationProvider} from "./components/AuthenticationContext";
import SignUpPage from "./pages/SignUpPage";
import CreateCreatorProfilePage from "./pages/CreateCreatorProfilePage";
import VideoUploadPage from "./pages/VideoUploadPage";
import CreatePlaylistFormPage from "./pages/CreatePlaylistFormPage";
import MuultiUserEditForm from "./components/MuultiUserEditForm";
import CreatorProfileEditForm from "./components/CreatorProfileEditForm";
import MuultiUserProfilePage from "./pages/MuultiUserProfilePage";
import VideoEditForm from "./components/VideoEditForm";
import UpdatePlaylistPage from "./pages/UpdatePlaylistPage";
import MuultiAboutPage from "./pages/MuultiAboutPage";
import CreatorProfile from "./components/PublicCreatorProfile";
import PublicCreatorProfile from "./components/PublicCreatorProfile";
require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const App: FC = () => {
    return (
        <Context>
            <Content />
        </Context>
    );
};
export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/solana-labs/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            new UnsafeBurnerWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const Content: FC = () => {
    return (
        <div className="App">
            <BrowserRouter>
                <AuthenticationProvider>
                <MuultiNavBar />
                <div className="page-container">
                    <Routes>

                        <Route path="/" element={<MuultiHomePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/signup" element={<SignUpPage/>} />
                        <Route path="/playlist/:id" element={<MuultiPlaylistDetailPage />} />
                        <Route path="/video-detail/:id" element={<VideoDetailPage/>} />
                        <Route path="/create-creator-profile" element={<CreateCreatorProfilePage/>} />
                        <Route path="/public-creator-profile/:id" element={<PublicCreatorProfile/>} />

                        <Route path="/upload-video" element={<VideoUploadPage/>}/>
                        <Route path="/create-playlist" element={<CreatePlaylistFormPage/>}/>
                        <Route path="/update-playlist/:playlistId" element={<UpdatePlaylistPage />} />
                        <Route path="/edit-user/:id" element={<MuultiUserEditForm/>}/>
                        <Route path="/edit-creator-profile/:id" element={<CreatorProfileEditForm/>}/>
                        <Route path="/user-profile" element={<MuultiUserProfilePage />} />
                        <Route path="/edit-video/:videoId" element={<VideoEditForm />}/>

                        <Route path="/about-page" element={<MuultiAboutPage/>}/>
                        {/* Add other routes as needed */}
                    </Routes>
                </div>
                </AuthenticationProvider>
            </BrowserRouter>
        </div>
    );
};
