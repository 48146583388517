import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthenticationContext } from '../components/AuthenticationContext';
import {Link} from "react-router-dom";
import { BASE_URL } from '../config';


const CreatorProfileComponent = () => {
    const { user, token } = useContext(AuthenticationContext);
    const [creatorProfile, setCreatorProfile] = useState(null);
    const [videos, setVideos] = useState([]);
    const [playlists, setPlaylists] = useState([]);

    useEffect(() => {
        const fetchCreatorProfile = async () => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                // Adjust the URL to your API endpoint
                const response = await axios.get(`${BASE_URL}/api/creator-profiles/${user?.creator_profile.id}`, config);
                setCreatorProfile(response.data);
                setVideos(response.data.videos); // Adjust according to your API response structure
                setPlaylists(response.data.playlists); // Adjust according to your API response structure
            } catch (error) {
                console.error("Error fetching creator profile:", error);
            }
        };

        if (user?.isCreatorUser) {
            fetchCreatorProfile();
        }
    }, [user, token]);

    if (!user?.isCreatorUser) {
        return <p>User does not have a Creator Profile.</p>;
    }

    return (
        <div>
            <h2>Creator Profile Information</h2>
            <p>Creator Name: {creatorProfile?.creator_name}</p>
            <p>Bio: {creatorProfile?.bio}</p>
            <div className="nav-link-container">
                <Link className="navbar-link"
                      to="/edit-creator-profile/:id"
                >
                    Edit Creator Profile
                </Link>
            </div>
            <h3>Videos</h3>
            <ul>
                {videos.map(video => (
                    <li key={video.id}>{video.video_name}</li>
                ))}
            </ul>
            <h3>Playlists</h3>
            <ul>
                {playlists.map(playlist => (
                    <li key={playlist.id}>{playlist.playlist_name}</li>
                ))}
            </ul>
        </div>
    );
};

export default CreatorProfileComponent;
