import CreateCreatorProfile from '../components/CreateCreatorProfileComponent';
import { AuthenticationContext } from '../components/AuthenticationContext';
import {useContext} from "react";

function CreateCreatorProfilePage() {
    const { token } = useContext(AuthenticationContext);
    return (
        <div>
            <h1>Create Your Creator Profile</h1>
            <CreateCreatorProfile />
            {/* Other components */}
        </div>
    );
}

export default CreateCreatorProfilePage;
