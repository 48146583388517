import React, { createContext, useContext } from "react";
import { CollectionMembershipContext } from "./CollectionMembershipProvider";

export const CollectionMatchContext = createContext({
    hasMatches: false,
    numMatches: 0,
});

const CollectionMatchProvider = ({ children }) => {
    const { collectionMintAddresses, nftMintAddresses } = useContext(
        CollectionMembershipContext
    );

    const cleanAddress = (address) => address.replace(/['"]+/g, "").trim();
    const cleanCollectionMintAddresses = collectionMintAddresses.map(cleanAddress);
    const cleanNftMintAddresses = nftMintAddresses.map(cleanAddress);

    const matchingMintAddresses = [];
    for (let i = 0; i < cleanCollectionMintAddresses.length; i++) {
        for (let j = 0; j < cleanNftMintAddresses.length; j++) {
            if (cleanCollectionMintAddresses[i] === cleanNftMintAddresses[j]) {
                matchingMintAddresses.push(cleanCollectionMintAddresses[i]);
                break;
            }
        }
    }

    const hasMatches = matchingMintAddresses.length > 0;
    const numMatches = matchingMintAddresses.length;
    console.log("Matches = " + numMatches);
    console.log("Has Matches: " + hasMatches);

    return (
        <CollectionMatchContext.Provider value={{ hasMatches, numMatches }}>
            {children}
        </CollectionMatchContext.Provider>
    );
};

export default CollectionMatchProvider;
