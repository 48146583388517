import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthenticationContext } from './AuthenticationContext';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../config';

function CreatorProfileEditForm() {
    const [creatorProfileData, setCreatorProfileData] = useState({
        creator_name: '',
        bio: '',
        website: '',
        creator_profile_picture: null,
        creator_profile_picture_preview: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
    const { token } = useContext(AuthenticationContext);
    const navigate = useNavigate();

    useEffect(() => {
        const apiUrl = `${BASE_URL}/api/creator-profile-update/`;
        if (token) {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.get(apiUrl, config)
                .then(response => {
                    setCreatorProfileData(response.data);
                })
                .catch(error => {
                    console.error("Error fetching creator profile details:", error);
                });
        }
    }, [token]);

    const handleInputChange = (event, field) => {
        if (field === 'creator_profile_picture') {
            const file = event.target.files[0];

            // If no file is provided, exit this block
            if (!file) return;

            // Validate file size
            const maxSize = 20 * 1024 * 1024; // 20MB in bytes
            if (file.size > maxSize) {
                setError("Profile picture size should be less than 20MB");
                event.target.value = ''; // Reset the input field
                return;
            }

            const previewURL = URL.createObjectURL(file);
            setCreatorProfileData(prevData => ({
                ...prevData,
                [field]: file,
                creator_profile_picture_preview: previewURL, // Set the preview URL here
            }));
        } else {
            setCreatorProfileData(prevData => ({
                ...prevData,
                [field]: event.target.value,
            }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const profileUpdateUrl = `${BASE_URL}/api/creator-profile-update/`;
        if (token) {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            };
            const formData = new FormData();
            Object.keys(creatorProfileData).forEach(key => {
                if (key !== 'creator_profile_picture_preview') {
                    if (key !== 'creator_profile_picture' || (key === 'creator_profile_picture' && creatorProfileData[key])) {
                        formData.append(key, creatorProfileData[key]);
                    }
                }
            });
            axios.put(profileUpdateUrl, formData, config)
                .then(response => {
                    console.log("Creator profile updated successfully:", response.data);
                    setIsSuccess(true);
                    setError(null);
                    setTimeout(() => {
                        navigate('/user-profile'); // Redirect to user profile after a short delay
                    }, 2000);
                })
                .catch(error => {
                    console.error("Error updating creator profile:", error);
                    setError("Failed to update creator profile. Please try again.");
                    setIsSuccess(false);
                });
        }
    };

    const handleDeleteCreatorProfile = async () => {
        const userConfirmed = window.confirm("THIS WILL DELETE ALL YOUR PLAYLISTS AND VIDEOS ASSOCIATED WITH YOUR CREATOR PROFILE. ARE YOU SURE YOU WANT TO DELETE YOUR CREATOR PROFILE?");
        if (!userConfirmed) {
            return; // Exit the function if the user clicked "Cancel"
        }

        setIsLoading(true);
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            await axios.delete(`${BASE_URL}/api/delete-creator-profile/`, config);
            setIsSuccess(true);
            setError(null);
            setTimeout(() => {
                navigate('/user-profile'); // Redirect to home after a short delay
            }, 2000);
        } catch (err) {
            console.log(err.response);
            setError(err.response ? err.response.data : 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-container">
            <h1>Edit your Creator Profile</h1>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <label>
                    Creator Name:
                    <input
                        type="text"
                        value={creatorProfileData.creator_name}
                        onChange={(e) => handleInputChange(e, 'creator_name')}
                    />
                </label>
                <label>
                    Bio:
                    <textarea
                        value={creatorProfileData.bio}
                        onChange={(e) => handleInputChange(e, 'bio')}
                    />
                </label>
                <label>
                    Website:
                    <input
                        type="url"
                        value={creatorProfileData.website}
                        onChange={(e) => handleInputChange(e, 'website')}
                    />
                </label>
                <label>
                    Profile Picture:
                    <div className="image-container">
                        <img
                            className="edit-profile-image"
                            src={creatorProfileData.creator_profile_picture_preview || creatorProfileData.creator_profile_picture}
                            alt={creatorProfileData.creator_name}
                        />
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleInputChange(e, 'creator_profile_picture')}
                    />
                </label>
                {isSuccess && <div className="success-message">Creator profile updated successfully!</div>}
                {error && <div className="error-message">{error}</div>}
                <div>
                    <button type="submit">Update Creator Profile</button>
                </div>
            </form>
            <div>
                <button onClick={handleDeleteCreatorProfile} disabled={isLoading} style={{backgroundColor: 'red', color: 'white'}}>Delete Creator Profile</button>
            </div>

        </div>
    );
}

export default CreatorProfileEditForm;
