import React from 'react';
import VideoDetailComponent from "../components/VideoDetailComponent";
import { useParams } from 'react-router-dom';

const VideoDetailPage = () => {
    const { id } = useParams();
    return(
        <div>
            <h1>Video Page</h1>
            <VideoDetailComponent id={id}/>
        </div>
    );
};

export default VideoDetailPage;
