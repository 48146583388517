import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthenticationContext } from './AuthenticationContext'; // Update the path
import { useNavigate, useParams } from 'react-router-dom';
import {BASE_URL} from "../config";

function MuultiUserEditForm() {
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        profile_picture: null,
        profile_description: '',
        profile_picture_preview: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    // Access token from context
    const { token } = useContext(AuthenticationContext);

    // Fetch user data when the component mounts
    useEffect(() => {
        const apiUrl = `${BASE_URL}/api/user-details/`;
        if (token) {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.get(apiUrl, config)
                .then(response => {
                    setUserData(response.data);
                })
                .catch(error => {
                    console.error("Error fetching user details:", error);
                });
        }
    }, [token]);

    const handleInputChange = (event, field) => {
        if (field === 'profile_picture') {
            const file = event.target.files[0];

            // Validate file size
            const maxSize = 20 * 1024 * 1024; // 5MB in bytes
            if (file && file.size > maxSize) {
                setError("Profile picture size should be less than 20MB");
                event.target.value = ''; // Reset the input field
                return;
            }

            const previewURL = URL.createObjectURL(file);
            setUserData(prevData => ({
                ...prevData,
                [field]: file,
                profile_picture_preview: previewURL, // Set the preview URL here
            }));
        } else {
            setUserData(prevData => ({
                ...prevData,
                [field]: event.target.value,
            }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userUpdateUrl = `${BASE_URL}/api/user-update/`;
        if (token) {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            };
            const formData = new FormData();
            Object.keys(userData).forEach(key => {
                // Check if the key is the profile_picture and if a new file has been chosen
                if (key !== 'profile_picture' || userData[key] instanceof File) {
                    formData.append(key, userData[key]);
                }
            });
            axios.put(userUpdateUrl, formData, config)
                .then(response => {
                    console.log("User updated successfully:", response.data);
                    setIsSuccess(true);
                    setError(null);
                    setTimeout(() => {
                        setIsSuccess(false); // Clear success message after 3 seconds
                        navigate('/user-profile'); // Navigate to the root page after a successful update
                    }, 3000);
                })
                .catch(error => {
                    console.error("Error updating user:", error);
                    setError("Failed to update profile. Please try again.");
                    setIsSuccess(false);
                });
        }
    };



    const handleDeleteUserAccount = async () => {
        const userConfirmed = window.confirm("THIS WILL DELETE YOUR ACCOUNTS AND ALL YOUR PLAYLISTS AND VIDEOS ASSOCIATED WITH YOUR CREATOR PROFILE. ARE YOU SURE YOU WANT TO DELETE YOUR CREATOR PROFILE?");
        if (!userConfirmed) {
            return; // Exit the function if the user clicked "Cancel"
        }
        setIsLoading(true);
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            await axios.delete(`${BASE_URL}/api/delete-user-account/`, config);
            setIsSuccess(true);
            setError(null);
            setTimeout(() => {
                navigate('/'); // Redirect to login after a short delay since the account is deleted
            }, 2000);
        } catch (err) {
            console.log(err.response);
            setError(err.response ? err.response.data : 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-container">
            <h1>Edit your User Information</h1>

            <form onSubmit={handleSubmit} encType="multipart/form-data">
                {isSuccess && <div className="alert alert-success">Profile updated successfully!</div>}
                {error && <div className="alert alert-danger">{error}</div>}
                <label>
                    Username:
                    <input
                        type="text"
                        value={userData.username}
                        onChange={(e) => handleInputChange(e, 'username')}
                    />
                </label>
                <label>
                    Email:
                    <input
                        type="email"
                        value={userData.email}
                        onChange={(e) => handleInputChange(e, 'email')}
                    />
                </label>
                <label>
                    Profile Description:
                    <textarea
                        value={userData.profile_description}
                        onChange={(e) => handleInputChange(e, 'profile_description')}
                    />
                </label>
                <label>
                    Profile Picture:
                    <div className="image-container">
                        <img
                            className="edit-profile-image"
                            src={userData.profile_picture_preview || `${BASE_URL}${userData.profile_picture}`}
                            alt={userData.username}
                        />
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleInputChange(e, 'profile_picture')}
                    />
                </label>

                <button type="submit">Update Profile</button>
            </form>
            <div>
                <button onClick={handleDeleteUserAccount} disabled={isLoading} style={{backgroundColor: 'red', color: 'white'}}>Delete Account</button>
            </div>
        </div>
    );
}

export default MuultiUserEditForm;
