import { CollectionMatchContext } from "./CollectionMatchProvider";
import React, { useContext } from "react";
import MuultiPlaylistListComponent from "./MuultiPlaylistListComponent";
import CreatorProfilesComponent from "./CreatorProfilesComponent";
import { useWallet } from '@solana/wallet-adapter-react';

const HomeVideoMenuComponent = () => {
    const { hasMatches, numMatches } = useContext(CollectionMatchContext);
    const publicKey = useWallet().publicKey;
    return (
        <div className="home-container">
            <div className="home-news-container">
                <h1>Muulti is a video platform where users can use any Solana NFT collection to token gate their video content.</h1>
                <h3>To view content, connect a solana wallet with the digital assets you need.</h3>
                <h3>To create a token gated video playlist</h3>
                <ol>
                    <li><strong>Connect your wallet.</strong> You need a connected Solana wallet with the corresponding NFTs to view a playlist, even if it's your video playlist. </li>
                    <li><strong>Register as a Muulti User.</strong> Your muulti user account is for logging in and as we build, it will be for communicating and for viewing preferences.</li>
                    <li><strong>Login</strong> Enter the platform.</li>
                    <li><strong>Make a creator profile.</strong> Your creator profile is for uploading videos and creating token gated video playlists and for creating NFT collections</li>
                    <li><strong>Upload some videos. Max 100MB for now.</strong> Upload multiple videos to be able to create a playlist. When you press the upload button, wait a moment for a success or error message to appear.</li>
                    <li><strong>Create a token gated video playlist.</strong> Choose from the videos that you've uploaded to make your playlist and then select any number of available NFT collections be a pass to view your playlists.</li>
                </ol>

            </div>
            <div>{
                useWallet().connected ? (
                    <>
                        <h3>Wallet Connected</h3>
                        {/*<p>You connected with {publicKey}</p>*/}
                        <div className="home-grid-container">
                            <div className="home-grid-item" id="home-playlist">
                                <MuultiPlaylistListComponent/>
                            </div>
                            <div className="home-grid-item" id="home-profiles">
                                <CreatorProfilesComponent/>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <h3>Please connect a Solana wallet to view content.</h3>
                        <p>Here are some wallets that we suggest:</p>
                        <ul>
                            <li><a href='https://phantom.app/' target="_blank" rel="noopener noreferrer">Phantom</a></li>
                            <li><a href='https://solflare.com/' target="_blank" rel="noopener noreferrer">Solflare</a></li>
                        </ul>
                    </div>


                )
            }
            </div>


        </div>
    );
}

export default HomeVideoMenuComponent;
