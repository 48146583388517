import React, {useContext, useState} from "react";
import MuultiPlaylistListComponent from '../components/MuultiPlaylistListComponent';
import CreatorProfilesComponent from "../components/CreatorProfilesComponent";
import WalletNFTComponent from "../components/WalletNFTComponent";
import CollectionMembershipProvider from "../components/CollectionMembershipProvider";
import CollectionMatchProvider from "../components/CollectionMatchProvider";
import HomeVideoMenuComponent from "../components/HomeVideoMenuComponent";


const MuultiHomePage = () => {
    const [hasMatches, setHasMatches] = useState(false);
    const [numMatches, setNumMatches] = useState(0);


    return (
        <div>
            {/*<CollectionMembershipProvider>*/}
            {/*    <CollectionMatchProvider*/}
            {/*        hasMatches={hasMatches}*/}
            {/*        numMatches={numMatches}*/}
            {/*        onHasMatchesChange={setHasMatches}*/}
            {/*        onNumMatchesChange={setNumMatches}*/}
            {/*    >*/}

                    <HomeVideoMenuComponent/>

                    {/*<WalletNFTComponent/>*/}
            {/*    </CollectionMatchProvider>*/}
            {/*</CollectionMembershipProvider>*/}
        </div>

    );
};

export default MuultiHomePage;
