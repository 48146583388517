import React from 'react';
import MuultiUserComponent from '../components/MuultiUserComponent';
import CreatorProfileComponent from '../components/CreatorProfileComponent';
import MuultiUserDetailComponent from "../components/MuultiUserDetailComponent";

const MuultiUserProfilePage = () => {
    return (
        <div>
            <MuultiUserDetailComponent />
        </div>
    );
};

export default MuultiUserProfilePage;
