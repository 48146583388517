import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthenticationContext } from './AuthenticationContext';
import { BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

const CreateCreatorProfileComponent = () => {
    const [state, setState] = useState({
        creator_name: '',
        bio: '',
        website: '',
        creator_profile_picture: null,
        error: null,
        isSuccess: false
    });

    const { token } = useContext(AuthenticationContext);
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (!file) return;

        // Check for image type
        if (!file.type.startsWith("image")) {
            setState(prevState => ({ ...prevState, error: "Invalid image format" }));
            event.target.value = ''; // Reset the input field
            return;
        }

        // Restrict image size to 20MB
        const maxSize = 20 * 1024 * 1024; // 20MB in bytes
        if (file.size > maxSize) {
            setState(prevState => ({ ...prevState, error: "Profile picture size should be less than 20MB" }));
            event.target.value = ''; // Reset the input field
            return;
        }

        setState(prevState => ({ ...prevState, creator_profile_picture: file, error: null }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { creator_name, bio, website, creator_profile_picture } = state;

        const formData = new FormData();
        formData.append('creator_name', creator_name);
        formData.append('bio', bio);
        formData.append('website', website);
        if (creator_profile_picture) {
            formData.append('creator_profile_picture', creator_profile_picture);
        }

        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            };
            const response = await axios.post(`${BASE_URL}/api/create-creator-profile/`, formData, config);
            console.log("Profile created:", response.data);
            setState(prevState => ({ ...prevState, isSuccess: true, error: null }));
            setTimeout(() => {
                navigate('/'); // Redirect to home after a short delay
            }, 2000);
        } catch (error) {
            console.error("Error creating profile:", error);
            setState(prevState => ({ ...prevState, error: "Error creating profile.", isSuccess: false }));
        }
    };

    const { creator_name, bio, website, error, isSuccess } = state;

    return (
        <div className="form-container">
            <h2>Create Creator Profile</h2>
            {isSuccess && <p>Profile successfully created!</p>}
            {error && <p>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Creator Name:</label>
                    <input type="text" name="creator_name" value={creator_name} onChange={handleChange} required />
                </div>
                <div>
                    <label>Bio:</label>
                    <textarea name="bio" value={bio} onChange={handleChange}></textarea>
                </div>
                <div>
                    <label>Website:</label>
                    <input type="url" name="website" value={website} onChange={handleChange} />
                </div>
                <div>
                    <label>Profile Picture:</label>
                    <input type="file" onChange={handleFileChange} />
                </div>
                <button type="submit">Create Profile</button>
            </form>
        </div>
    );
}

export default CreateCreatorProfileComponent;
