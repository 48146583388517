import React, {useContext, useState} from 'react';
import axios from 'axios';
import {AuthenticationContext} from "./AuthenticationContext";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';


const VideoUploadComponent = () => {
    const { token } = useContext(AuthenticationContext);
    const [formData, setFormData] = useState({
        video_name: '',
        video_description: '',
        video_file: null,
        video_preview_image: null
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize useHistory

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (!file) return;

        // File size limit: 20MB in bytes
        const imageMaxSize = 20 * 1024 * 1024;
        const videoMaxSize = 750 * 1024 * 1024;

        if (name === "video_file") {
            if (!file.type.startsWith("video")) {
                setError("Invalid video format");
                e.target.value = ''; // Reset the input field
                return;
            }
            if (file.size > videoMaxSize) {
                setError("Video size should be less than 750MB");
                e.target.value = ''; // Reset the input field
                return;
            }
        }

        if (name === "video_preview_image") {
            if (!file.type.startsWith("image")) {
                setError("Invalid image format");
                e.target.value = ''; // Reset the input field
                return;
            }
            if (file.size > imageMaxSize) {
                setError("Preview image size should be less than 20MB");
                e.target.value = ''; // Reset the input field
                return;
            }
        }

        // If no errors, update the formData state
        setFormData(prevState => ({
            ...prevState,
            [name]: file
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        };

        const uploadData = new FormData();
        uploadData.append('video_name', formData.video_name);
        uploadData.append('video_description', formData.video_description);
        uploadData.append('video_file', formData.video_file);
        uploadData.append('video_preview_image', formData.video_preview_image);

        try {
            await axios.post(`${BASE_URL}/api/upload-video/`, uploadData, config);
            setIsSuccess(true);
            setError(null);
            setTimeout(() => {
                navigate('/'); // Redirect to home after a short delay
            }, 2000); // 25 seconds delay
        } catch (err) {
            setError(err.response ? err.response.data : 'An error occurred');
        }
    };

    return (
        <div className="form-container">
            <h2>Video Upload</h2>
            {isSuccess && <div className="success-message">Video uploaded successfully! Redirecting...</div>}
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Video Name:</label>
                    <input type="text" name="video_name" value={formData.video_name} onChange={handleChange} required />
                </div>
                <div>
                    <label>Description:</label>
                    <input type="text" name="video_description" value={formData.video_description} onChange={handleChange} required />
                </div>
                <div>
                    <label>Video File(Max 750MB):</label>
                    <input type="file" accept="video/*" name="video_file" onChange={handleFileChange} required />
                </div>
                <div>
                    <label>Video Preview Image(Max 20MB):</label>
                    <input type="file" accept="image/*" name="video_preview_image" onChange={handleFileChange} required />
                </div>
                <div>
                    <button type="submit">Upload Video</button>
                </div>
            </form>
        </div>
    )
};

export default VideoUploadComponent;
