import React, { useState, useEffect } from 'react';
import { Connection, GetProgramAccountsFilter } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

const WalletNFTComponent = () => {
    const [tokenAccounts, setTokenAccounts] = useState([]);
    const wallet = "Gt3r1syr4Em1TTyRUabToQjkZZDVPyvLrL13XFJ3nqUj";

    useEffect(() => {
        const fetchTokenAccounts = async () => {
            const solana = new Connection("https://distinguished-crimson-resonance.solana-mainnet.quiknode.pro/2018028efcf5287402f6d94d444f397195f2df4a/");

            const filters = [
                {
                    dataSize: 165
                },
                {
                    memcmp: {
                        offset: 32,
                        bytes: wallet,
                    }
                }
            ];

            const accounts = await solana.getParsedProgramAccounts(TOKEN_PROGRAM_ID, { filters });
            setTokenAccounts(accounts);
        };

        fetchTokenAccounts();
    }, []);

    return (
        <div>
            {tokenAccounts.map((account, i) => {
                const parsedAccountInfo = account.account.data;
                // console.log(account.account);
                const uri = parsedAccountInfo.parsed?.uri;
                const mintAddress = parsedAccountInfo?.parsed?.info?.mint;
                const tokenBalance = parsedAccountInfo?.parsed?.info?.tokenAmount?.uiAmount;
                return (
                    <div key={i}>
                        <p>{i}.) Token Account: {account.pubkey.toString()}</p>
                        <p>URI: {uri}</p>
                        <p>Mint Address: {mintAddress}</p>
                        <p>Token Balance: {tokenBalance}</p>
                        {JSON.stringify(parsedAccountInfo)}
                    </div>
                );
            })}
        </div>
    );
};

export default WalletNFTComponent;
