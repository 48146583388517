import React, { createContext, useContext, useState, useEffect } from "react";
import { PlaylistCollectionMembershipContext } from "./PlaylistCollectionMembershipProvider";

export const PlaylistCollectionMatchContext = createContext({
    hasMatches: false,
    numMatches: 0
});

const PlaylistCollectionMatchProvider = ({ children }) => {
    const { collectionMintAddresses, nftMintAddresses } = useContext(
        PlaylistCollectionMembershipContext
    );

    const [hasMatches, setHasMatches] = useState(false);
    const [numMatches, setNumMatches] = useState(0);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cleanAddress = (address) => address.replace(/['"]+/g, "").trim();
        const cleanCollectionMintAddresses = collectionMintAddresses.map(cleanAddress);
        const cleanNftMintAddresses = nftMintAddresses.map(cleanAddress);

        const matchingMintAddresses = [];
        for (let i = 0; i < cleanCollectionMintAddresses.length; i++) {
            for (let j = 0; j < cleanNftMintAddresses.length; j++) {
                if (cleanCollectionMintAddresses[i] === cleanNftMintAddresses[j]) {
                    matchingMintAddresses.push(cleanCollectionMintAddresses[i]);
                    console.log("MATCH");
                    break;
                }
            }
        }

        const newHasMatches = matchingMintAddresses.length > 0;
        const newNumMatches = matchingMintAddresses.length;
        console.log("newHasMatches " + newHasMatches);
        console.log("newNumMatches" + newNumMatches);
        setHasMatches(newHasMatches);
        setNumMatches(newNumMatches);
        setLoading(false);
    }, [collectionMintAddresses, nftMintAddresses]);

    if (loading) {
        return <div>Loading matches...</div>;
    }

    return (
        <PlaylistCollectionMatchContext.Provider value={{ hasMatches, numMatches }}>
            {children}
        </PlaylistCollectionMatchContext.Provider>
    );
};

export default PlaylistCollectionMatchProvider;
