import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config';
import { Link } from 'react-router-dom';


class CreatorProfilesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profiles: [],
            isLoading: true,
            error: null
        };
    }

    componentDidMount() {
        axios.get(`${BASE_URL}/api/creator-profiles/`)
            .then(response => {
                this.setState({ profiles: response.data, isLoading: false });
            })
            .catch(error => {
                this.setState({ error: error.message, isLoading: false });
            });
    }

    render() {
        const { profiles, isLoading, error } = this.state;

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div>
                <h1>Creator Profiles</h1>
                <div className="home-profiles-container">
                    {profiles.map(profile => (
                        <Link to={`/public-creator-profile/${profile.id}`}>


                        <div className="home-profile-item" key={profile.id}>
                            <h3>{profile.creator_name}</h3>
                            <div className="home-profile-image-container">
                                <img className="home-profile-image" src={profile.creator_profile_picture} alt={profile.creator_name} />
                            </div>
                            <p>{profile.bio}</p>
                            {/*<a href={profile.website}>{profile.website}</a>*/}
                        </div>
                        </Link>
                    ))}
                </div>
            </div>
        );
    }
}

export default CreatorProfilesComponent;
