import React, { useState, useContext, useRef, useEffect } from "react";
import PlaylistCollectionMatchProvider, {PlaylistCollectionMatchContext} from "./PlaylistCollectionMatchProvider";
import {Link} from "react-router-dom";
import { BASE_URL } from '../config';

const PlaylistVideos = ({ playlist }) => {
    const { hasMatches } = useContext(PlaylistCollectionMatchContext);
    const [selectedVideo, setSelectedVideo] = useState(playlist.video_playlist[0]); // Set initial state to the first video object
    const videoRef = useRef(null); // Reference to the video element

    const handleVideoClick = (video) => {
        setSelectedVideo(video);
    };

    const handleVideoEnd = () => {
        incrementViewCount(selectedVideo.id);
    };

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('ended', handleVideoEnd);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, [selectedVideo]);

    const incrementViewCount = async (videoId) => {
        console.log(`Incrementing view count for video ID: ${videoId}`);
        try {
            const response = await fetch(`${BASE_URL}/api/increment-view/${videoId}/`, { method: 'POST' });
            const data = await response.json();
            if (data.status === 'success') {
                console.log('View count incremented');
            } else {
                console.error('Failed to increment view count:', data.message);
            }
        } catch (error) {
            console.error('Error incrementing view count:', error);
        }
    };



    useEffect(() => {
        if (videoRef.current && selectedVideo) {
            videoRef.current.load(); // Reload the video element when the source changes
        }
    }, [selectedVideo]);



    if (hasMatches) {
        return (
            <>
                <div className="playlist-grid-container">
                {selectedVideo && (
                    <div className="video-player-section" id="playlist-current-video-item">
                        <h1>{selectedVideo.video_name}</h1>

                        <div className="video-player">
                            <video ref={videoRef} key={selectedVideo.video_file} controls onEnded={() => incrementViewCount(selectedVideo.id)}>
                                <source src={selectedVideo.video_file} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <h4>Author: {selectedVideo.video_author.creator_name}</h4>
                        <p>{selectedVideo.video_description}</p>


                    </div>
                )}
                <div id="playlist-items-container">
                <h1>Video Selection</h1>
                <div className="video-grid">
                    {playlist.video_playlist.map(video => (
                        <div className="video-item" key={video.id} onClick={() => handleVideoClick(video)}>
                            <div className="video-image-container">
                                <img className="video-image-image" src={video.video_preview_image} width="100%" />
                            </div>
                            <h3>{video.video_name}</h3>
                            <p>Views: {video.view_count }</p>
                            <p>{video.video_author.creator_name}</p>
                            <p>{video.video_description}</p>
                            <p>{playlist.playlist_name}</p>
                        </div>
                    ))}
                </div>
                </div>
                </div>
            </>
        );
    } else {
        return <p>Please Attach a wallet with the correct Tokens.</p>;
    }
};

export default PlaylistVideos;
