import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

const SignUpForm = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '', // New state for confirmation password
        profile_picture: null,
        profile_description: ''
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        // Check for image type
        if (!file.type.startsWith("image")) {
            setError("Invalid image format");
            e.target.value = ''; // Reset the input field
            return;
        }

        // Restrict image size to 20MB
        const maxSize = 20 * 1024 * 1024; // 20MB in bytes
        if (file.size > maxSize) {
            setError("Profile picture size should be less than 20MB");
            e.target.value = ''; // Reset the input field
            return;
        }

        // Update formData if validations pass
        setFormData(prevState => ({
            ...prevState,
            profile_picture: file
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match.");
            return; // Return early to prevent API call
        }

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            if (key !== 'confirmPassword') { // Don't append confirmPassword to formData
                data.append(key, formData[key]);
            }
        });

        try {
            await axios.post(`${BASE_URL}/api/register_user/`, data);
            setIsSuccess(true);
            setError(null);
            setTimeout(() => {
                navigate('/'); // Redirect to home after a short delay
            }, 2000);
        } catch (err) {
            setError(err.response ? err.response.data : 'An error occurred');
        }
    };

    return (
        <div className="form-container">
            <h2>Sign Up</h2>
            {isSuccess && <div>Registration successful!</div>}
            {error && <div>Error: {JSON.stringify(error)}</div>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Username:</label>
                    <input type="text" name="username" value={formData.username} onChange={handleChange} required />
                </div>
                <div>
                    <label>Email:</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div>
                    <label>Password:</label>
                    <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                </div>
                <div>
                    <label>Confirm Password:</label>
                    <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                </div>
                <div>
                    <label>Profile Picture:</label>
                    <input type="file" accept="image/*" name="profile_picture" onChange={handleFileChange} />
                </div>
                <div>
                    <label>Profile Description:</label>
                    <textarea name="profile_description" value={formData.profile_description} onChange={handleChange}></textarea>
                </div>
                <div>
                    <button type="submit">Sign Up</button>
                </div>
            </form>
        </div>
    );
};

export default SignUpForm;
