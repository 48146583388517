import React, { Component } from "react";
import '../assets/styles/MuultiNavBarStyle.css';
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import logo from "../assets/images/muulti_logo_one.png" ;
import { Link } from 'react-router-dom';
import { AuthenticationContext } from './AuthenticationContext';

class MuultiNavBar extends Component{

    render(){
        return(
            <AuthenticationContext.Consumer>
                {({ isAuthenticated, user, logout, hasCreatorProfile  }) => {
                    return(
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="logo-image-container">
                    <div className="logo-image-container">
                        <img className="logo-image" src={logo} alt='Muulti'/>
                    </div>
                </Link>
                <Link to="/">
                <div className="site-title-container">
                    <h3>Muulti Beta</h3>
                </div>
                </Link>
                    <div className="navbar-links">
                        <div className="nav-link-container">
                            <Link className="navbar-link" to="/">Home</Link>
                        </div>
                        <div className="nav-link-container">
                            <Link className="navbar-link" to="/about-page">About</Link>
                        </div>
                        {isAuthenticated? (
                            <>
                                <div className="nav-link-container">
                                    <Link className="navbar-link"
                                          to="/user-profile"
                                    >
                                        Your Profile
                                    </Link>
                                </div>
                                { hasCreatorProfile  ? (
                                    <>
                                   <div className="nav-link-container">
                                       <Link className="navbar-link"
                                             to="/upload-video"
                                       >
                                           Upload Video
                                       </Link>
                                   </div>
                                    <div className="nav-link-container">
                                        <Link className="navbar-link"
                                              to="/create-playlist"
                                        >
                                            Create Playlist
                                        </Link>
                                    </div>

                                   </>
                                ) : (
                                    <div className="nav-link-container">
                                        <Link className="navbar-link"
                                              to="/create-creator-profile"
                                        >
                                            Create Creator Profile
                                        </Link>
                                    </div>
                                )

                                }

                                <div className="nav-link-container">
                                    <Link
                                        className="navbar-link"
                                        to="/"
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent navigation
                                            logout();
                                        }}
                                    >
                                        Logout
                                    </Link>
                                </div>

                            </>
                            )
                            :(
                                <>
                                    <div className="nav-link-container">
                                        <Link className="navbar-link" to="/login">Login</Link>
                                    </div>
                                    <div className="nav-link-container">
                                        <Link className="navbar-link" to="/signup">SignUp</Link>
                                    </div>

                                </>
                        )}
                    </div>
                    <div className="nav-wallet-container">
                        <WalletMultiButton/>
                    </div>
            </div>
        </nav>
                    )
                }}
            </AuthenticationContext.Consumer>
        );
    }
}
export default MuultiNavBar;
