import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import VideoDetailPage from "../pages/VideoDetailPage";
import PlaylistVideos from './PlaylistVideos'; // Adjust the path accordingly
import PlaylistCollectionMembershipProvider from "./PlaylistCollectionMembershipProvider";
import PlaylistCollectionMatchProvider, {PlaylistCollectionMatchContext} from "./PlaylistCollectionMatchProvider";
import { BASE_URL } from '../config';


const MuultiPlaylistDetailComponent = (props) => {
    const [playlist, setPlaylist] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { hasMatches, numMatches, setHasMatches, setNumMatches } = useContext(PlaylistCollectionMatchContext);


    useEffect(() => {
        const { id } = props; // Assuming the ID of the playlist is passed as a prop

        axios.get(`${BASE_URL}/api/token-gated-playlists/${id}/`)
            .then(response => {
                setPlaylist(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                setError(err);
                setIsLoading(false);
            });
    }, [props]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const collectionMintAddresses = playlist.digital_asset_collection_gates.flatMap(nft_collection =>
        nft_collection.nft_collection_mint_addresses.split(/[\[\],]+/).filter(Boolean)
    );

    return (
        <div>
            <h1>{playlist.playlist_name}</h1>
            <h3>Playlist Token Gate Collections: </h3>
            <p>
            {playlist.digital_asset_collection_gates.map(nft_collection => (
                   <> {nft_collection.nft_collection_name}, </>
            ))}
        </p>
            <PlaylistCollectionMembershipProvider collectionMintAddresses={collectionMintAddresses}>
                <PlaylistCollectionMatchProvider>
                    <PlaylistVideos playlist={playlist} />
                </PlaylistCollectionMatchProvider>
            </PlaylistCollectionMembershipProvider>


        </div>
    );
}

export default MuultiPlaylistDetailComponent;
