import React from 'react';
import MuultiPlaylistDetailComponent from '../components/MuultiPlaylistDetailComponent';
import { useParams } from 'react-router-dom';

const MuultiPlaylistDetailPage = () => {
    const { id } = useParams();

    return (
        <div>
            <MuultiPlaylistDetailComponent id={id} />
            {/* You can add more components or content here as needed */}
        </div>
    );

};

export default MuultiPlaylistDetailPage;
