import React, { useState, createContext, useEffect } from 'react';
import axios from "axios";
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, GetProgramAccountsFilter } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { BASE_URL } from '../config';


export const CollectionMembershipContext = createContext();

const CollectionMembershipProvider = (props) => {
    const [collectionMintAddresses, setCollectionMintAddresses] = useState([]);
    const ownerPublicKey = useWallet().publicKey;
    const [nftMintAddresses, setNftMintAddresses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`${BASE_URL}/api/nft-collections/`)
            .then((res) => {
                const addresses = res.data.flatMap((item) =>
                    item.nft_collection_mint_addresses.split(/[\[\],]+/).filter(Boolean)
                );
                setCollectionMintAddresses(addresses);
            });
    }, []);

    useEffect(() => {
        const fetchTokenAccounts = async () => {
            const solana = new Connection("https://distinguished-crimson-resonance.solana-mainnet.quiknode.pro/2018028efcf5287402f6d94d444f397195f2df4a/");

            const filters = [
                {
                    dataSize: 165
                },
                {
                    memcmp: {
                        offset: 32,
                        bytes: ownerPublicKey?.toString() || "",
                    }
                }
            ];

            const accounts = await solana.getParsedProgramAccounts(TOKEN_PROGRAM_ID, { filters });
            const mintAddresses = accounts.map(account => account.account.data.parsed?.info?.mint).filter(Boolean);
            setNftMintAddresses(mintAddresses);
            // console.log(mintAddresses);
            setIsLoading(false);
        };

        if (ownerPublicKey) {
            fetchTokenAccounts();
        }
    }, [ownerPublicKey]);

    if (isLoading) {
        return <p>Connect your Solana wallet to view content.</p>;
    }

    return (
        <CollectionMembershipContext.Provider value={{ nftMintAddresses, collectionMintAddresses }}>
            {props.children}
        </CollectionMembershipContext.Provider>
    );
};

export default CollectionMembershipProvider;
