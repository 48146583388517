import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link, useParams} from 'react-router-dom';
import { BASE_URL } from '../config';

function PublicCreatorProfile() {
    const {id} = useParams();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/creator-profile/${id}/`)
            .then(response => {
                setProfile(response.data);
            })
            .catch(error => {
                console.error('Error fetching creator profile:', error);
            });
    }, [id]);


    if (!profile) {
        return <div>Loading...</div>;
    }

    return (
        <div className="public-creator-profile-container">
            <h1>Creator Profile</h1>
            <div className="public-creator-profile-info">

                <div className="public-profile-image-container">
                    <img className="profile-image" src={profile.creator_profile_picture} alt={profile.creator_name}/>
                </div>
                <div className="public-creator-inner-info">
                    <h1>{profile.creator_name}</h1>
                    <p>{profile.bio}</p>
                    <a href={profile.website} target="_blank" rel="noopener noreferrer">{profile.website}</a>
                </div>

            </div>
            <h1>{profile.creator_name}'s Playlists</h1>
            <div className="public-profile-playlist-container">

                {profile.channel_playlist.map(playlist => (
                    <Link to={`/playlist/${playlist.id}`}>
                    <div className="public-profile-playlist-item">
                        <h3>{playlist.playlist_name}</h3>
                        <img className="playlist-image" src={playlist.playlist_image} alt={playlist.playlist_name}/>
                        <p>{playlist.playlist_description}</p>

                        <h4>Videos in this playlist:</h4>
                        <div className="public-profile-video-container">

                            {playlist.video_playlist.map(video => (
                                <div className="public-profile-video-item">

                                    <div className="public-video-image-container">
                                        <img className="public-video-image" src={video.video_preview_image} alt={video.video_name}/>
                                    </div>
                                        <h4>{video.video_name}</h4>
                                    {/*<p>{video.video_description}</p>*/}
                                </div>
                            ))}
                        </div>
                        <div>
                            <h4>Token Gates Collections:</h4>
                            <p>
                                {playlist.digital_asset_collection_gates.map(collection => (
                                    <>{collection.nft_collection_name} </>
                                ))}
                            </p>
                        </div>
                    </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}
        export default PublicCreatorProfile;
