import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config';


export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
    const initialToken = localStorage.getItem('accessToken');
    const [isAuthenticated, setIsAuthenticated] = useState(Boolean(initialToken));
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(initialToken || null);

    const [hasCreatorProfile, setHasCreatorProfile] = useState(false);

    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
            setToken(storedToken);
            setIsAuthenticated(true);
            // Fetch user details using the stored token
            const fetchUserDetails = async () => {
                try {
                    const config = {
                        headers: { Authorization: `Bearer ${storedToken}` }
                    };
                    const response = await axios.get(`${BASE_URL}/api/user-details/`, config);
                    setUser(response.data);
                    setHasCreatorProfile(response.data.isCreatorUser);
                } catch (error) {
                    console.error("Error fetching user details:", error);
                }
            };
            fetchUserDetails();
        }
    }, []);

    const login = async (accessToken, refreshToken) => {
        setIsAuthenticated(true);
        setToken(accessToken);
        localStorage.setItem('accessToken', accessToken);
        console.log("Access Token: " + accessToken);
        try {
            const config = {
                headers: { Authorization: `Bearer ${accessToken}` }
            };
            const response = await axios.get(`${BASE_URL}/api/user-details/`, config);
            setUser(response.data);
            setHasCreatorProfile(response.data.isCreatorUser);
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUser(null);
        setToken(null);
        localStorage.removeItem('accessToken');
    };

    return (
        <AuthenticationContext.Provider value={{ isAuthenticated, user, token, login, logout, hasCreatorProfile }}>
            {children}
        </AuthenticationContext.Provider>
    );
};
