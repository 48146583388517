import React, {useContext, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import UpdatePlaylistFormComponent from '../components/UpdatePlaylistFormComponent';
import {AuthenticationContext} from "../components/AuthenticationContext";
import { BASE_URL } from '../config';

const UpdatePlaylistPage = () => {
    const { playlistId } = useParams();
    const [playlistData, setPlaylistData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const {token} = useContext(AuthenticationContext);

    useEffect(() => {
        const fetchPlaylistData = async () => {
            try {
                setIsLoading(true);
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                // If you don't need to send any data with the POST request, you can omit the `data` parameter
                const response = await axios.get(`${BASE_URL}/api/playlist-update/${playlistId}/`, config);
                console.log("Fetched Playlist Data Response: \n", response.data);
                setPlaylistData(response.data);
                console.log("Fetching Playlist Data from Page: \n" + JSON.stringify(playlistData));
            } catch (error) {
                setError('Error fetching playlist data');
                console.error("Error fetching playlist data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPlaylistData();
    }, [playlistId, token]);

    useEffect(() => {
        console.log("Fetching Playlist Data from Page: \n" + JSON.stringify(playlistData));
    }, [playlistData]);

    return (
        <div>
            <h2>Update Playlist</h2>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p style={{color: 'red'}}>{error}</p>
            ) : (
                <UpdatePlaylistFormComponent playlistData={playlistData} />
            )}
        </div>
    );
};

export default UpdatePlaylistPage;
