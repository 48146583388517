import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthenticationContext } from './AuthenticationContext';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../config';

function VideoEditForm() {
    const { videoId } = useParams();
    const [videoData, setVideoData] = useState({
        video_name: '',
        video_description: '',
        video_file: null,
        video_preview_image: null,
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [previewImage, setPreviewImage] = useState(null);
    const { token, user } = useContext(AuthenticationContext);
    const navigate = useNavigate();

    useEffect(() => {
        const apiUrl = `${BASE_URL}/api/video-update/${videoId}/`;
        if (token) {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.get(apiUrl, config)
                .then(response => {
                    // Check if video_author and creator_profile are defined before accessing their id properties
                    if (response.data.video_author?.id !== user.creator_profile?.id) {
                        navigate('/'); // Redirect to home page
                    } else {
                        setVideoData(response.data);
                    }
                })
                .catch(error => {
                    console.error("Error fetching video details:", error);
                });
        }
        console.log("Video Data: " + JSON.stringify(videoData))
    }, [token, videoId, user, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVideoData({
            ...videoData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        // Validate file size
        let maxSize;
        if (name === "video_file") {
            maxSize = 100 * 1024 * 1024; // 50MB in bytes
            if (file && file.size > maxSize) {
                setErrorMessage("Video size should be less than 100MB");
                e.target.value = ''; // Reset the input field
                return;
            }
        } else if (name === "video_preview_image") {
            maxSize = 20 * 1024 * 1024; // 20MB in bytes
            if (file && file.size > maxSize) {
                setErrorMessage("Video preview image size should be less than 20MB");
                e.target.value = ''; // Reset the input field
                return;
            }
        }

        // If there's no error, proceed to update the videoData state
        setVideoData(prevState => ({
            ...prevState,
            [name]: file
        }));

        // If it's the preview image, create its URL for displaying purposes
        if (name === 'video_preview_image') {
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(videoData).forEach(key => {
            // Check if the key is one of the file fields and if a new file has been chosen
            if ((key !== 'video_file' && key !== 'video_preview_image') || videoData[key] instanceof File) {
                formData.append(key, videoData[key]);
            }
        });

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        };

        axios.put(`${BASE_URL}/api/video-update/${videoId}/`, formData, config)
            .then(response => {
                console.log("Video updated successfully:", response.data);
                setSuccessMessage('Video updated successfully!');
                setErrorMessage(''); // Clear any previous error message
                setTimeout(() => {
                    navigate('/'); // Redirect to home after a short delay
                }, 2000); // 25 seconds delay // Redirect to home page or video detail page
            })
            .catch(error => {
                console.error("Error updating video:", error);
                setErrorMessage('Error updating video. Please try again.');
                setSuccessMessage(''); // Clear any previous success message
            });
    };

    const handleDelete = () => {
        const userConfirmed = window.confirm("Are you sure you want to delete this video?");
        if (!userConfirmed) {
            return; // Exit the function if the user clicked "Cancel"
        }

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.delete(`${BASE_URL}/api/delete-video/${videoId}/`, config)
            .then(response => {
                console.log("Video deleted successfully:", response.data);
                navigate('/user-profile'); // Redirect to home page after successful deletion
            })
            .catch(error => {
                console.error("Error deleting video:", error);
                setErrorMessage('Error deleting video. Please try again.');
            });
    };

    return (
        <div className="form-container">
            <h1>Edit {videoData.video_name}</h1>
            {successMessage && <div className="success-message">{successMessage}</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <form onSubmit={handleSubmit}>
                <label>
                    Video Name:
                    <input type="text" name="video_name" value={videoData.video_name} onChange={handleChange} />
                </label>
                <label>
                    Video Description:
                    <textarea name="video_description" value={videoData.video_description} onChange={handleChange} />
                </label>
                <label>
                    Current Video:
                    {videoData.video_file ? (
                        <video width="320" height="240" controls>
                            <source src={videoData.video_file} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
                </label>
                <label>
                    Video File:
                    <input type="file" name="video_file" onChange={handleFileChange} />
                </label>
                <label>
                    Current Preview Image:
                    {previewImage ? (
                        <img src={previewImage} alt="Selected Preview" width="320" height="240" />
                    ) : videoData.video_preview_image ? (
                        <img src={videoData.video_preview_image} alt="Current Preview" width="320" height="240" />
                    ) : null}
                </label>
                <label>
                    Video Preview Image:
                    <input type="file" name="video_preview_image" onChange={handleFileChange} />
                </label>
                <button type="submit">Update Video</button>
            </form>
            <div>
                <button type="button" onClick={handleDelete}>Delete Video</button>
            </div>
        </div>
    );
}

export default VideoEditForm;
