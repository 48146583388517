import { AuthenticationContext } from "./AuthenticationContext";
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';

const CreatePlaylistFormComponent = () => {
    const { token } = useContext(AuthenticationContext);
    const [formData, setFormData] = useState({
        playlist_name: '',
        playlist_description: '',
        playlist_banner: null,
        playlist_image: null,
        video_playlist_ids: [],
        digital_asset_collection_gate_ids: []
    });
    const [videos, setVideos] = useState([]);
    const [nftCollections, setNftCollections] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVideosAndNFTs = async () => {
            const config = {
                headers: { 'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'}
            };
            try {
                const videosResponse = await axios.get(`${BASE_URL}/api/get_videos_by_author/`, config);
                setVideos(videosResponse.data);

                const nftResponse = await axios.get(`${BASE_URL}/api/nft-collections/`, config);
                setNftCollections(nftResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchVideosAndNFTs();
    }, [token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (e.target.multiple) {
            setFormData(prevState => ({
                ...prevState,
                [name]: Array.from(e.target.selectedOptions, option => option.value)
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        // Restrict image size to 20MB
        const maxSize = 20 * 1024 * 1024; // 20MB in bytes
        if (file.size > maxSize) {
            setError("Selected file is too large. Please choose a file less than 20MB.");
            e.target.value = ''; // Reset the input field
            return;
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: file
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        };

        const uploadData = new FormData();
        Object.keys(formData).forEach(key => {
            if (Array.isArray(formData[key])) {
                formData[key].forEach(value => {
                    uploadData.append(key, value);  // Use the model's field name directly
                });
            } else {
                uploadData.append(key, formData[key]);
            }
        });

        try {
            for (let pair of uploadData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }

            await axios.post(`${BASE_URL}/api/create-playlist/`, uploadData, config); // Updated endpoint
            setIsSuccess(true);
            setError(null);
            setTimeout(() => {
                navigate('/'); // Redirect to home after a short delay
            }, 2000);
        } catch (err) {
            setError(err.response && err.response.data.detail ? err.response.data.detail : 'An error occurred');

        }
    };

    return (
        <div className="form-container">
            <h2>Create Playlist</h2>
            {isSuccess && <p style={{color: 'green'}}>Playlist created successfully!</p>}
            {error && <p style={{color: 'red'}}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Playlist Name: </label>
                    <input type="text" name="playlist_name" value={formData.playlist_name} onChange={handleChange} required />
                </div>
                <div>
                    <label>Playlist description: </label>
                    <input type="text" name="playlist_description" value={formData.playlist_description} onChange={handleChange} required />
                </div>
                <div>
                    <label>Select Videos:</label>
                    <select multiple name="video_playlist" onChange={handleChange}>
                        {videos.map(video => (
                            <option key={video.id} value={video.id}>{video.video_name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Select NFT Collections:</label>
                    <select multiple name="digital_asset_collection_gates" onChange={handleChange}>
                        {nftCollections.map(nft => (
                            <option key={nft.id} value={nft.id}>{nft.nft_collection_name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Playlist Preview Image:</label>
                    <input type="file" name="playlist_image" onChange={handleFileChange} required />
                </div>
                <div>
                    <label>Playlist Preview Banner:</label>
                    <input type="file" name="playlist_banner" onChange={handleFileChange} required />
                </div>
                <div>
                    <button type="submit">Create Playlist</button>
                </div>
            </form>
        </div>
    )
};

export default CreatePlaylistFormComponent;
