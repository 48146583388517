import React from 'react';
import CreatePlaylistFormComponent from "../components/CreatePlaylistFormComponent";


const CreatePlaylistFormPage = () => {
    return(
        <div>
            <h2>Upload Video Page</h2>
            <CreatePlaylistFormComponent/>
        </div>
    )
};

export default CreatePlaylistFormPage;
