import React, { useContext } from 'react';
import LoginForm from '../components/LoginForm';
import { AuthenticationContext } from '../components/AuthenticationContext';

const LoginPage = () => {
    const { isAuthenticated } = useContext(AuthenticationContext);

    if (isAuthenticated) {
        return <p>You are already logged in!</p>;
    }

    return (
        <div>
            <h2>Login</h2>
            <LoginForm />
        </div>
    );
};

export default LoginPage;
