import React from 'react';
import VideoUploadComponent from "../components/VideoUploadComponent";

const VideoUploadPage = () => {
    return(
        <div>
            <h2>Upload Video Page</h2>
            <VideoUploadComponent/>
        </div>
    )
};

export default VideoUploadPage;
