import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthenticationContext } from './AuthenticationContext';
import { Link } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { BASE_URL } from '../config';

function MuultiUserDetailComponent() {
    const [userData, setUserData] = useState(null);
    const [walletProfile, setWalletProfile] = useState(null);
    const {token} = useContext(AuthenticationContext);
    const wallet = useWallet();
    const publicKey = wallet.publicKey?.toString();
    const publicKeyExists = walletProfile?.public_keys?.some(key => key.public_key === publicKey) || false;


    useEffect(() => {
        console.log("Base URL: " + BASE_URL);
        const fetchUserDetails = async () => {
            try {
                const config = {
                    headers: {Authorization: `Bearer ${token}`}
                };
                const response = await axios.get(`${BASE_URL}/api/authenticated-user-detail/`, config);
                setUserData(response.data);
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };
        if (token) fetchUserDetails();
    }, [token]);

    useEffect(() => {
        const fetchWalletProfile = async () => {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            if (userData && userData.id) {
                try {
                    const response = await axios.get(`${BASE_URL}/api/view-wallet-profile/${userData.id}/`, config);
                    setWalletProfile(response.data);
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        console.log("User does not have a wallet profile yet.");
                    } else {
                        console.error("Error fetching wallet profile:", error);
                    }
                }
            }
        };

        if (userData) {
            fetchWalletProfile();
        }
    }, [userData, token]);

    const handleAddWallet = async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            const data = {
                public_key: publicKey
            };
            if (!walletProfile) {
                await axios.post(`${BASE_URL}/api/create-wallet-profile/`, {}, config);
            }
            await axios.post(`${BASE_URL}/api/add-public-key/`, data, config);
            const walletResponse = await axios.get(`${BASE_URL}/api/view-wallet-profile/${userData.id}/`, config);
            setWalletProfile(walletResponse.data);
        } catch (error) {
            console.error("Error adding wallet:", error);
        }
    };

    return (
        <div className="profile-container">
            {userData ? (
                <>
                    <div>
                        <h1>{userData.username} Profile Page</h1>
                    </div>
                    <div className="profile-display-container">
                        <div className="profile-item image-container">
                            <div className="profile-image-container">
                                <img className="profile-image" src={userData.profile_picture} alt="Profile"/>
                            </div>
                        </div>
                        <div className="profile-item info-container">
                            <h3>User Profile</h3>
                            <p><strong>Username:</strong> {userData.username}</p>
                            <p><strong>Description:</strong> {userData.profile_description}</p>
                            <p><strong>Email:</strong> {userData.email}</p>
                            <p><strong>Creator User:</strong> {userData.isCreatorUser.toString()}</p>
                            <div className="nav-link-container">
                                <Link className="navbar-link" to="/edit-user/:id">
                                    Edit User
                                </Link>
                            </div>
                        </div>

                        {userData.creator_profile ? (
                            <div className="profile-item info-container">
                                <h3>Creator Profile</h3>
                                <p><strong>Creator Name:</strong> {userData.creator_profile.creator_name}</p>
                                <p><strong>Creator Bio:</strong> {userData.creator_profile.bio}</p>
                                <p><strong>Website:</strong><Link
                                    to={userData.creator_profile.website}> {userData.creator_profile.website}</Link></p>
                                <div className="nav-link-container">
                                    <Link className="navbar-link" to="/edit-creator-profile/:id">
                                        Edit Creator Profile
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Link to="/create-creator-profile">
                                    Create Creator Profile
                                </Link>
                            </div>
                        )}
                        <div className="profile-item info-container">
                        <div className="wallet-management">
                            <h3>Your Profile Wallets</h3>
                            {walletProfile ? (
                                <>
                                    <div>
                                        {walletProfile.public_keys?.map(key => (
                                            <p key={key.id}>{key.public_key}</p>
                                        ))}
                                    </div>
                                    {!publicKeyExists && <button onClick={handleAddWallet}>+ Add Current Wallet Address</button>}
                                </>
                            ) : (
                                <>
                                    <p>You don't have a wallet profile yet.</p>
                                    <button onClick={handleAddWallet}>Create Wallet Profile and Add Current Wallet Address</button>
                                </>
                            )}
                        </div>
                        </div>

                    </div>



                    {userData.creator_profile ? (
                        <>
                            <h3>Your Videos</h3>
                            <div className="video-grid">

                                {userData.creator_profile.video.length > 0 ? (
                                    userData.creator_profile.video.map(vid => (
                                        <div key={vid.id} className="video-item">
                                            <div className="video-image-container">
                                                <img src={vid.video_preview_image} width="100%" />
                                            </div>
                                            <h4>{vid.video_name}</h4>
                                            <p>{vid.video_description}</p>
                                            <Link to={`/edit-video/${vid.id}`}>Edit Video</Link>
                                        </div>
                                    ))
                                ) : (
                                    <h3>You haven't uploaded any videos.</h3>
                                )}
                            </div>
                            <h3>Playlists</h3>
                            <div className="playlist-grid">
                                {userData.creator_profile.channel_playlist.length > 0 ? (
                                    userData.creator_profile.channel_playlist.map(playlist => (
                                        <div key={playlist.id} className="playlist-item">
                                            <div className="playlist-image">
                                                <img src={playlist.playlist_image} width="100%" alt="Profile Image"/>
                                            </div>
                                            <h4>{playlist.playlist_name}</h4>
                                            <p>{playlist.playlist_description}</p>
                                            <Link to={`/update-playlist/${playlist.id}`}>Edit Playlist</Link>
                                        </div>
                                    ))
                                ) : (
                                    <h3>You haven't created any playlists.</h3>
                                )}
                            </div>
                        </>
                    ) : null}
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );

}
    export default MuultiUserDetailComponent;
