import CreateCreatorProfile from '../components/CreateCreatorProfileComponent';
import { AuthenticationContext } from '../components/AuthenticationContext';
import React, {useContext} from "react";

function MuultiAboutPage() {
    const { token } = useContext(AuthenticationContext);
    return (
        <div className="about-container">
            <h1>About the Muulti Video Platform</h1>
            <h3>Muulti is a video platform where users can use any Solana NFT collection to token gate their video content.</h3>
            <h3>This video platform makes a major impact on the broader Solana ecosystem and the world at large because it gives everyone a reason to use NFTs and it gives all projects the ability to create token gated video content.</h3>
            <h3>This video platform disrupts the idea that monetary value is determined by views, likes and the decisions of the platform providers. With Muulti, the monetary value of the content becomes an agreement between the content creators and their audience, through their NFT collections.</h3>
            <h3>This video platform provides a valuable utility for all other NFT projects to use.</h3>
            <h3>It's built with simplicity in mind to make it easy for users to onboard into using NFTs with the platform.</h3>
            <h1>FAQ</h1>
            <ul>
                <li>All video content on Muulti are viewed through playlists.</li>
                <li>When a user goes to watch videos on a playlist channel, their Solana wallet will get checked for any matches for the corresponding playlist, before allowing the user to view the content.</li>
                <li>Content creators can select any Solana NFT collection, currently available, to provide a ticketing/token gated system for their video content.</li>
                <li>To create playlists, you must register as a user and then as a creator and then you can upload videos and create playlists.</li>
                <li>We are currently working on a more web3 approach for creator's to manage token gates. We are currently adding NFT collections by selection but all will be available in the near future.</li>
            </ul>
            <h1>Current Activities</h1>
                <h3>Solana Hyperdrive Hackathon</h3>
                <p>Muulti was entered into the Solana Hyperdrive Hackathon 2023</p>
                <p><a href="https://hyperdrive.solana.com/projects/explore?nameQuery=Muulti" target="_blank" rel="noopener noreferrer">Find our project in the directory here.</a></p>
                <p><a href="https://drive.google.com/file/d/1AZFGGZo8SVLh70fALJKACHNhc5VB6I1R/view?usp=sharing" target="_blank" rel="noopener noreferrer">View the presentation here.</a></p>

            <h1>Known Issues</h1>
            <ul>
                <li>JWT authentication tokens fall out of sync with authentication status. You may have to logout and login again.</li>
                <li>When editing a creator profile, you have to upload a creator profile image to for it to succeed.</li>
            </ul>

            <h1>Future Plans</h1>
            <ul>
                <li>
                    Provide a medium for creators to make their own NFT collections to token gate their content or other creator's content as well.
                </li>
                <li>
                    There are many web2 features that we could step away from, currently. Moving from web2 paradigms to more web3 paradigms where it is safe and delightful for regular users.
                </li>
                <li>
                    An NFT ticket marketplace where users can trade NFT/tickets and creators could earn royalties.
                </li>
                <li>
                    Genres, tags, categories and comments
                </li>
            </ul>
            <h1>Support Muulti</h1>
            <p>Currently you can support Muulti by hyping it up!</p>
            <p>On Social Media Follow:</p>
            <ul>
                <li><a href="https://twitter.com/muulti_">Muulti</a></li>
                <li><a href="https://twitter.com/Simulacrum_img">Simulations</a></li>
            </ul>
            <p>You can support by buying a Meta Microwave - (Which might act like a master key in the near future)</p>
            <p>You can also send Solana donations to:</p>
            <p>Div3RQvTo4JXaoRsPfTxP8K96c3tYVpMk3ksyVgHG2St</p>
        </div>
    );
}

export default MuultiAboutPage;
