import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../components/AuthenticationContext';

const MuultiUserComponent = () => {
    const { user } = useContext(AuthenticationContext);

    return (
        <div>
            <h1>MuultiUser Information</h1>
            <p>Username: {user?.username}</p>
            <p>Email: {user?.email}</p>
            <p>Profile Description: {user?.profile_description}</p>
            <div className="nav-link-container">
                <Link className="navbar-link"
                      to="/edit-user/:id"
                >
                    Edit User
                </Link>
            </div>
        </div>
    );
};

export default MuultiUserComponent;
