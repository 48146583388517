import React, { Component } from 'react';
import axios from 'axios'; // Importing axios directly
import '../assets/styles/MuultiStyles.css';
import {Link} from "react-router-dom";
import { BASE_URL } from '../config';

class MuultiPlaylistListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playlists: [],
            isLoading: true,
            error: null
        };
    }

    componentDidMount() {
        // Using axios directly to make the GET request
        axios.get(`${BASE_URL}/api/token-gated-playlists/`)
            .then(response => {
                this.setState({ playlists: response.data, isLoading: false });
            })
            .catch(error => {
                this.setState({ error, isLoading: false });
            });
    }

    render() {
        const { playlists, isLoading, error } = this.state;

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        }

        return (
            <div>
                <h1>Playlists</h1>
                <div className="playlist-grid">
                    {playlists.map(playlist => (
                        <>
                            {playlist.video_playlist.length > 0 &&(
                                <div className="playlist-item" key={playlist.id}>
                                    <Link to={`/playlist/${playlist.id}`}>
                                        <h1>{playlist.playlist_name}</h1>

                                    <div className="playlist-image">
                                        <img src={playlist.playlist_image} width="100%" alt="Profile Image"/>
                                    </div>
                                    <div>
                                        <p><strong>Author:</strong> {playlist.playlist_author.creator_name}</p>
                                        <p><strong>Description:</strong> {playlist.playlist_description}</p>
                                        <p><strong>Number of Videos:</strong> {playlist.video_playlist.length}</p>
                                    </div>
                                    {/*<ul>*/}
                                    {/*    <h4>Videos:</h4>*/}
                                    {/*    {playlist.video_playlist.map(video => (*/}
                                    {/*        <li key={video.id}>{video.video_name}</li>*/}
                                    {/*    ))}*/}
                                    {/*</ul>*/}
                                    <div>
                                        <div>
                                            <h4>Token Gates Collections:</h4>
                                            <p>
                                            {playlist.digital_asset_collection_gates.map(collection => (
                                                <>{collection.nft_collection_name} </>
                                            ))}
                                            </p>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </div>
        );
    }
}

export default MuultiPlaylistListComponent;
