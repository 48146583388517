import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config';

class VideoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: null,
            isLoading: true,
            error: null
        };
    }

    componentDidMount() {
        const videoId = this.props.id; // Directly access id from props
        axios.get(`${BASE_URL}/api/video-detail/${videoId}/`)
            .then(response => {
                this.setState({ video: response.data, isLoading: false });
            })
            .catch(error => {
                this.setState({ error: error.message, isLoading: false });
            });
    }

    render() {
        const { video, isLoading, error } = this.state;

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div>
                <h2>{video.video_name}</h2>
                <img src={video.video_preview_image} alt={video.video_name} />
                <p>{video.video_description}</p>
                <video controls src={video.video_file}></video>
                <p>Author: {video.video_author.creator_name}</p>
            </div>
        );
    }
}

export default VideoDetail;
