import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthenticationContext } from '../components/AuthenticationContext'; // Update the path accordingly
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';


const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const { login, user, hasCreatorProfile } = useContext(AuthenticationContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/api/token/`, {
                username,
                password
            });
            const { access, refresh } = response.data;
            // Call the login function from the AuthenticationContext
            login(access, refresh);
            setSuccess('Logged in successfully!');
            navigate('/');
        } catch (err) {
            console.error(err);
            if (err.response && err.response.data) {
                if (err.response.data.detail) {
                    setError(err.response.data.detail);
                } else {
                    setError('Invalid username or password');
                }
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Username:</label>
                    <input
                        type="text"
                        value={username}  // Assuming 'username' is the state variable for this input
                        onChange={(e) => setUsername(e.target.value)}  // Assuming 'setUsername' is the setter function for 'username'
                        autoComplete="username"
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                </div>
                <button type="submit">Login</button>
            </form>
            {error && <p>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}
        </div>
    );
};

export default LoginForm;
