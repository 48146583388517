import React, { useContext, useState, useEffect } from "react";
import { AuthenticationContext } from "./AuthenticationContext";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../config';

const UpdatePlaylistFormComponent = ({ playlistData }) => {
    const { token } = useContext(AuthenticationContext);
    const [formData, setFormData] = useState({
        playlist_name: playlistData ? playlistData.playlist_name : '',
        playlist_description: playlistData ? playlistData.playlist_description : '',
        playlist_banner: null,
        playlist_image: null,
        video_playlist_ids: playlistData && playlistData.video_playlist_ids ? playlistData.video_playlist_ids : [],
        digital_asset_collection_gate_ids: playlistData && playlistData.digital_asset_collection_gate_ids ? playlistData.digital_asset_collection_gate_ids : [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [nftCollections, setNftCollections] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { playlistId } = useParams();

    useEffect(() => {
        const fetchVideosAndNFTs = async () => {
            const config = {
                headers: { 'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'}
            };
            try {
                const videosResponse = await axios.get(`${BASE_URL}/api/get_videos_by_author/`, config);
                setVideos(videosResponse.data);

                const nftResponse = await axios.get(`${BASE_URL}/api/nft-collections/`, config);
                setNftCollections(nftResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchVideosAndNFTs();
    }, [token]);

    useEffect(() => {
        if (playlistData) {
            setFormData({
                playlist_name: playlistData.playlist_name || '',
                playlist_description: playlistData.playlist_description || '',
                playlist_banner: null,
                playlist_image: null,
                video_playlist_ids: playlistData.video_playlist_ids || [],
                digital_asset_collection_gate_ids: playlistData.digital_asset_collection_gate_ids || [],
            });
            console.log("Form Data: " + JSON.stringify(formData));
        }
    }, [playlistData]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        if (e.target.multiple) {
            let stateName = name;
            if (name === 'video_playlist') stateName = 'video_playlist_ids';
            if (name === 'digital_asset_collection_gates') stateName = 'digital_asset_collection_gate_ids';
            setFormData(prevState => ({
                ...prevState,
                [stateName]: Array.from(e.target.selectedOptions, option => option.value)
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        // Validate file size
        const maxSize = 20 * 1024 * 1024; // 20MB in bytes
        if (file && file.size > maxSize) {
            setError("Profile picture size should be less than 20MB");
            e.target.value = ''; // Reset the input field
            return;
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: file
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        };

        const uploadData = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'playlist_banner' || key === 'playlist_image') {
                if (formData[key] !== null) {
                    uploadData.append(key, formData[key]);
                }
            } else if (Array.isArray(formData[key])) {
                formData[key].forEach(value => {
                    uploadData.append(`${key}`, value); // Append each value with the same key
                });
            } else {
                uploadData.append(key, formData[key]);
            }
        });

        try {
            await axios.put(`${BASE_URL}/api/playlist-update/${playlistId}/`, uploadData, config);
            setIsSuccess(true);
            setError(null);
            setTimeout(() => {
                navigate('/user-profile'); // Redirect to home after a short delay
            }, 2000);
        } catch (err) {
            console.log(err.response);
            setError(err.response ? err.response.data : 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        const userConfirmed = window.confirm("Are you sure you want to delete this video?");
        if (!userConfirmed) {
            return; // Exit the function if the user clicked "Cancel"
        }
        setIsLoading(true);
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            await axios.delete(`${BASE_URL}/api/delete-playlist/${playlistId}/`, config);
            setIsSuccess(true);
            setError(null);
            setTimeout(() => {
                navigate('/user-profile'); // Redirect to home after a short delay
            }, 2000);
        } catch (err) {
            console.log(err.response);
            setError(err.response ? err.response.data : 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-container">
            <h2>Update Playlist</h2>
            {isLoading && <p>Loading...</p>}
            {isSuccess && <p style={{color: 'green'}}>Playlist updated successfully!</p>}
            {error && <p style={{color: 'red'}}>{error}</p>}

            <form onSubmit={handleSubmit}>
                <div>
                    <label>Playlist Name: </label>
                    <input type="text" name="playlist_name" value={formData.playlist_name} onChange={handleChange} required />
                </div>
                <div>
                    <label>Playlist Description: </label>
                    <textarea name="playlist_description" value={formData.playlist_description} onChange={handleChange} required />
                </div>
                <div>
                    <label>Select Videos:</label>
                    <select multiple name="video_playlist" value={formData.video_playlist_ids} onChange={handleChange}>
                        {videos.map(video => (
                            <option key={video.id} value={video.id}>{video.video_name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Select NFT Collections:</label>
                    <select multiple name="digital_asset_collection_gates" value={formData.digital_asset_collection_gate_ids} onChange={handleChange}>
                        {nftCollections.map(nft => (
                            <option key={nft.id} value={nft.id}>{nft.nft_collection_name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Playlist Preview Image:</label>
                    {playlistData && playlistData.playlist_image && (
                        <div>
                            <img src={playlistData.playlist_image} alt="Current Playlist Image" width="100" />
                            <p>Current Image</p>
                        </div>
                    )}
                    <input type="file" name="playlist_image" onChange={handleFileChange} />
                </div>
                <div>
                    <label>Playlist Preview Banner:</label>
                    {playlistData && playlistData.playlist_banner && (
                        <div>
                            <img src={playlistData.playlist_banner} alt="Current Playlist Banner" width="100" />
                            <p>Current Banner</p>
                        </div>
                    )}
                    <input type="file" name="playlist_banner" onChange={handleFileChange} />
                </div>
                <div>
                    <button type="submit" disabled={isLoading}>Update Playlist</button>
                </div>
            </form>
            <div>
                <button onClick={handleDelete} disabled={isLoading} style={{backgroundColor: 'red', color: 'white'}}>Delete Playlist</button>
            </div>
        </div>
    )
};

export default UpdatePlaylistFormComponent;
